const _ = require('lodash');

export const state = {
    pinnedIds: [],
    pinnedLocationIds: []
};

export const mutations = {
    TOGGLE_PINNED(state, id) {
        state.pinnedIds = _.xor(state.pinnedIds, [id]);
    },
    TOGGLE_PINNED_LOCATION(state, id) {
        state.pinnedLocationIds = _.xor(state.pinnedLocationIds, [id]);
    }
};

export const getters = {
    pinnedIds: (state) => {
        return state.pinnedIds;
    },
    pinnedLocationIds: (state) => {
        return state.pinnedLocationIds;
    }
};

export const actions = {
    togglePinned({commit}, id) {
        commit('TOGGLE_PINNED', id);
    },
    togglePinnedLocation({commit}, id) {
        commit('TOGGLE_PINNED_LOCATION', id);
    }
};