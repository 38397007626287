<template>
  <div class="admin-edit-announcement-template">
    <loading-screen
      :is-loading="isSaving || isDeleting || isLoading"
    ></loading-screen>

    <div class="loaded" v-if="!isLoading && quickMessage">
      <div class="page-header">
        <h1 class="page-title">Edit Announcement Template</h1>
        <div class="actions">
          <a-button
            class="button-margin-left btn-rounded"
            icon="arrow-left"
            size="large"
            type="primary"
            @click.prevent="goBack"
            >Back</a-button
          >

          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="attemptDelete"
          >
            <a-button
              class="button-margin-left btn-rounded"
              icon="delete"
              size="large"
              type="danger"
              >Delete</a-button
            >
          </a-popconfirm>
        </div>
      </div>

      <div class="form">
        <a-form-item label="Title">
          <a-input
            size="large"
            v-model="quickMessage.displayName"
          ></a-input>
        </a-form-item>

        <a-form-item label="Description">
          <a-textarea
            :rows="6"
            size="large"
            v-model="quickMessage.body"
          ></a-textarea>
        </a-form-item>

        <a-form-item label="Visibility">
          <a-radio-group v-model="quickMessage.scope">
            <a-radio :value="1">Shown to organisation</a-radio>
            <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
            <a-radio :value="3">Hidden from lists</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Behavior">
          <a-radio-group v-model="quickMessage.attributes.AppendOnly">
            <a-radio :value="false">Replace existing content</a-radio>
            <a-radio :value="true">Append to existing content</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Urgent">
          <a-switch v-model="quickMessage.attributes.IsUrgent" />
        </a-form-item>

        <a-form-item label="Desktop Alert">
          <a-switch v-model="quickMessage.attributes.DesktopAlert" />
        </a-form-item>

        <a-form-item label="NetSupport Notify">
          <a-switch v-model="quickMessage.attributes.NetNotify" />
        </a-form-item>

        <a-form-item label="Default Group">
          <a-select
            v-model="quickMessage.attributes.ToManagedGroupId"
            placeholder="Select default group (optional)"
            style="width: 100%"
            size="large"
            allowClear
          >
            <a-select-option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
            >
              {{ group.displayName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Announcement Type">
          <a-select
            v-model="quickMessage.attributes.PresetId"
            placeholder="Select announcement type (optional)"
            style="width: 100%"
            size="large"
            allowClear
          >
            <a-select-option
              v-for="preset in announcementPresets"
              :key="preset.id"
              :value="preset.id"
            >
              {{ preset.displayName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import quickMessages from "../../../../api/quick-messages";
import axios from "axios";
import chats from "../../../../api/chats";

export default {
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      quickMessage: null,
      groups: [],
      announcementPresets: [],
    };
  },

  components: { LoadingScreen },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },

  methods: {
    goBack() {
      this.$router.push("/admin/settings/announcement-templates");
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      quickMessages
        .deleteQuickMessage(this.tenantId, this.quickMessage.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Announcement template deleted successfully");
          vm.$router.push("/admin/settings/announcement-templates");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error deleting announcement template");
          vm.isDeleting = false;
        });
    },

    loadQuickMessage() {
      let vm = this;
      vm.isLoading = true;
      quickMessages
        .getQuickMessage(vm.tenantId, vm.$route.params.id)
        .then((r) => {
          vm.isLoading = false;
          
          // Initialize attributes first
          const attributes = {
            AppendOnly: false,
            IsUrgent: false,
            DesktopAlert: false,
            NetNotify: false,
            ToManagedGroupId: null,
            PresetId: null
          };

          // Merge existing attributes if they exist
          if (r.data.attributes) {
            Object.keys(attributes).forEach(key => {
              if (r.data.attributes[key] !== undefined) {
                // Convert string booleans to actual booleans for boolean properties
                if (typeof attributes[key] === 'boolean') {
                  attributes[key] = r.data.attributes[key] === true || 
                                  r.data.attributes[key] === "true";
                } else {
                  attributes[key] = r.data.attributes[key];
                }
              }
            });
          }

          // Use Vue.set to ensure reactivity
          vm.quickMessage = {
            ...r.data,
            attributes: attributes
          };
          
          this.loadGroups();
          this.loadAnnouncementPresets();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading announcement template");
          vm.$router.push("/admin/settings/announcement-templates");
        });
    },

    loadGroups() {
      let vm = this;
      chats
        .getManagedChatsForOrg(this.tenantId, this.selectedOrganisation.id)
        .then(r => {
          vm.groups = r.data;
        })
        .catch(e => {
          console.log(e);
          vm.$message.error("Error loading groups");
        });
    },

    loadAnnouncementPresets() {
      // Add method to load announcement presets
      let vm = this;
      axios.get(`${window.API_BASE}/api/v1/${this.tenantId}/Orgs/${this.selectedOrganisation.id}/AnnouncementPresets`)
        .then(r => {
          vm.announcementPresets = r.data;
        })
        .catch(e => {
          console.log(e);
          vm.$message.error("Error loading announcement presets");
        });
    },

    getValidationErrors() {
      let errors = [];
      if (this.quickMessage.displayName.trim().length == 0) {
        errors.push("Please provide a title");
      }
      if (this.quickMessage.body.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      // Create a copy of the quickMessage and convert boolean attributes to strings
      const payload = {
        ...this.quickMessage,
        attributes: {
          ...this.quickMessage.attributes,
          AppendOnly: this.quickMessage.attributes.AppendOnly.toString(),
          IsUrgent: this.quickMessage.attributes.IsUrgent.toString(),
          DesktopAlert: this.quickMessage.attributes.DesktopAlert.toString(),
          NetNotify: this.quickMessage.attributes.NetNotify.toString()
        }
      };

      try {
        await quickMessages.updateQuickMessage(
          this.tenantId,
          payload
        );

        vm.isSaving = false;
        vm.$message.success("Announcement template updated successfully");
        vm.$router.push("/admin/settings/announcement-templates");
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error("Error updating announcement template");
      }
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadQuickMessage();
    }
  },
};
</script>

<style scoped lang="scss">
.admin-edit-announcement-template {
  padding-bottom: 20px;

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>