var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"api-client-wrapper"},[_c('div',{staticClass:"api-client",on:{"click":function($event){$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"left"},[_c('api-client-thumbnail',{attrs:{"tenant-id":_vm.tenantId,"api-client":_vm.apiClient}})],1),(_vm.isLoading)?_c('div',{staticClass:"right"},[_c('a-spin')],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.apiClient.displayName)+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.apiClient.headline)+" ")])]):_vm._e()]),(_vm.linkTarget && _vm.incidentTags)?_c('a-modal',{attrs:{"centered":"","title":_vm.apiClient.displayName,"ok-button-props":{ props: { disabled: !_vm.canShare } }},on:{"ok":_vm.share},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('loading-screen',{attrs:{"is-loading":_vm.isSaving}}),_vm._l((_vm.controls),function(control,i){return _c('div',{key:i},[(_vm.shouldShowDebug(control))?_c('div',[_vm._v(_vm._s(control))]):_vm._e(),(
          control['$t'] ==
          'Soteria.Models.LinkTargetStringControl, Soteria.Models'
        )?_c('link-target-string-control',{attrs:{"control":control,"incident":_vm.incident,"params":_vm.params}}):_vm._e(),(
          control['$t'] ==
          'Soteria.Models.LinkTargetLookupControl, Soteria.Models'
        )?_c('link-target-lookup-control',{attrs:{"control":control,"params":_vm.params,"tenant-id":_vm.tenantId,"org-id":_vm.orgId,"api-client-id":_vm.apiClient.id,"incident-tags":_vm.incidentTags}}):_vm._e()],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }