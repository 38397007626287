import axios from 'axios';

export default {
    getTagProviders(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/providers');
    },

    getTags(tenantId, orgId, provider=null, containing=null, type=null) {
        // only if provider is set, include query string
        let queryString = '';
        if (provider) {
            queryString = '?provider=' + provider;
        }
        if (containing) {
            if (queryString) {  
                queryString += '&';
            } else {
                queryString = '?';
            }
            queryString += 'containing=' + containing;
        }
        if (type) {
            if (queryString) {
                queryString += '&';
            } else {
                queryString = '?';
            }
            queryString += 'type=' + type;
        }
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags' + queryString);
    },

    getTagsForGroup(tenantId, orgId, groupType, groupId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId);
    },

    addTagToGroup(tenantId, orgId, groupType, groupId, provider, type, providersId, description) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId, {
            provider,
            type,
            providersId,
            description
        });
    },

    removeTagFromGroup(tenantId, orgId, groupType, groupId, tagsProvider, tagType, tagId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId + '/' + tagsProvider + '/' + tagType + '/' + tagId);
    },

    getItemsByTag(tenantId, orgId, groupType, tagsProvider, tagType, tagId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + tagsProvider + '/' + tagType + '/' + tagId);
    }
}
