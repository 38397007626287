<template>
  <div class="admin-templates">
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings - Templates
      </h1>
    </div>

    <!-- Menu -->
    <div class="menu">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="(item, i) in menuItems" :key="i">
          <admin-menu-item
            :img="item.img"
            :title="item.title"
            @click.native="() => handleItemClicked(item)"
          ></admin-menu-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Menu -->
  </div>
</template>

<script>
import AdminMenuItem from "../../../components/Admin/AdminMenuItem.vue";

export default {
  components: { AdminMenuItem },
  
  data() {
    return {
      windowWidth: window.innerWidth,

      menuItems: [
        {
          title: "Quick Messages",
          onClick: () => {
            this.$router.push("/admin/settings/quick-messages");
          },
          img: "/admin/quick-messages.jpg",
        },
        {
          title: "Announcement Templates",
          onClick: () => {
            this.$router.push("/admin/settings/announcement-templates");
          },
          img: "/admin/announcement-templates.jpg",
        },
        {
          title: "Quick Incident Responses",
          onClick: () => {
            this.$router.push("/admin/settings/quick-incident-responses");
          },
          img: "/admin/quick-messages.jpg",
        }
      ],
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  computed: {
    colSpan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      if (this.windowWidth > 500) {
        return 24;
      }
      return 24;
    },
  },

  methods: {
    handleItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.admin-templates {
  .menu {
    margin-top: 25px;
  }
}
</style>
