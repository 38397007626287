<template>
  <div class="admin">
    <h1 style="font-size: 30px">Admin</h1>
    <organisation-selector></organisation-selector>

    <!-- Menu -->
    <div class="menu">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="(item, i) in menuItemsWithTooltips" :key="i">
          <admin-menu-item
            :img="item.img"
            :title="item.title"
            :tooltip="item.tooltip"
            @click.native="() => handleItemClicked(item)"
          ></admin-menu-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Menu -->
  </div>
</template>

<script>
import OrganisationSelector from "../components/Admin/OrganisationSelector.vue";
import AdminMenuItem from "../components/Admin/AdminMenuItem.vue";
import tooltips from '../tooltips.json';

export default {
  components: { OrganisationSelector, AdminMenuItem },
  data() {
    return {
      windowWidth: window.innerWidth,
      tooltips,

      menuItems: [
        {
          title: "Dashboard",
          onClick: () => {
            this.$router.push("/admin/stats");
          },
          img: "/admin/dashboard.jpeg",
        },
        {
          title: "Managed Groups",
          onClick: () => {
            this.$router.push("/admin/managed-groups");
          },
          img: "/admin/managed_groups.jpeg",
        },
        {
          title: "Announcements",
          onClick: () => {
            this.$router.push("/admin/announcements");
          },
          img: "/admin/announcements.jpeg",
        },
        {
          title: "Active Incidents",
          onClick: () => {
            this.$router.push("/admin/incidents");
          },
          img: "/admin/incidents.jpeg",
        },
        {
          title: "Closed Incidents",
          onClick: () => {
            this.$router.push("/admin/archived-incidents");
          },
          img: "/admin/closed_incidents.jpeg",
        },
        {
          title: "Reporting",
          onClick: () => {
            this.$router.push("/admin/reporting");
          },
          img: "/admin/reports.jpeg",
        },
        {
          title: "Report By Tag",
          onClick: () => {
            this.$router.push("/admin/report-by-tag");
          },
          img: "/admin/reports.jpeg",
        },
        {
          title: "Guidance",
          onClick: () => {
            this.$router.push("/admin/guidance");
          },
          img: "/admin/guidance.jpg",
        },
        {
          title: "Integration",
          onClick: () => {
            this.$router.push("/admin/integration");
          },
          img: "/admin/integration.jpg",
        },
        {
          title: "Users",
          onClick: () => {
            this.$router.push("/admin/users");
          },
          img: "/admin/users.jpg",
        },
        {
          title: "Responders",
          onClick: () => {
            this.$router.push("/admin/responders");
          },
          img: "/admin/responders-new.jpg",
        },
        {
          title: "Settings",
          onClick: () => {
            this.$router.push("/admin/settings");
          },
          img: "/admin/all-settings.jpg",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    handleItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
    },
  },
  computed: {
    colSpan() {
      if (this.windowWidth > 1400) {
        return 6;
      }
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 600) {
        return 12;
      }
      if (this.windowWidth > 400) {
        return 24;
      }
      return 24;
    },
    menuItemsWithTooltips() {
      return this.menuItems.map(item => ({
        ...item,
        tooltip: this.tooltips.Admin?.[item.title]
      }));
    }
  },
};
</script>

<style scoped lang="scss">
.menu {
  margin-top: 20px;
}

.admin-menu-item {
  margin-bottom: 15px;
}
</style>