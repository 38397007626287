<template>
  <a-modal class="create-announcement-modal" centered v-model="showModal" title="New Announcement">
    <template slot="footer">
      <a-button v-if="stage === 0" key="back" @click="$emit('close-modal')">
        Cancel
      </a-button>
      <a-button v-if="stage === 0" :disabled="!canAnnounce" key="submit" type="primary" @click.prevent="goToNextStage">
        Next
      </a-button>

      <a-button v-if="stage === 1" key="back" @click="goBack" :disabled="isSaving">
        Back
      </a-button>
      <a-button v-if="stage === 1" key="submit" :loading="isSaving" type="primary" @click.prevent="persist">
        Create Announcement
      </a-button>
    </template>

    <!-- Stage 0 -->
    <div class="inner" v-if="stage == 0">
      <!-- Organisation selector -->
      <a-tabs :class="{ hidden: organisations.length == 1 }" @change="selectOrgById"
        :activeKey="selectedOrganisation.id">
        <a-tab-pane v-for="organisation in organisationsToShow" :key="organisation.id" :disabled="orgId">
          <template slot="tab">
            <organisation-thumbnail class="org-thumbnail" :tenantId="getOrgTenantId(organisation)"
              :orgId="organisation.id" size="small"></organisation-thumbnail>
            {{ organisation.displayName }}
          </template>
        </a-tab-pane>
        <a-tab-pane v-for="organisation in organisationsIAmNotAnAdminFor" disabled :key="organisation.id"
          :tab="organisation.displayName">
        </a-tab-pane>
      </a-tabs>
      <!-- / Organisation selector -->

      <!-- Loading -->
      <div class="loader" v-show="isLoading && selectedOrganisation">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- Must have org selected -->
      <div v-show="!isLoading && selectedOrganisation">
    

        <!-- Group selector -->
        <div>
          <a-form-item label="Group" style="margin-bottom: 7px">
            <a-select v-model="selectedGroupId" :disabled="isLoadingGroups" :loading="isLoadingGroups" size="large"
              class="group-selector">
              <a-select-option v-for="group in groupDropdownOptions" :value="group.id" :key="group.id">
                {{ group.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <!-- / Group selector -->

        <!-- Announcement selector -->
        <div style="margin-top: 5px">
          <a-form-item label="Announcement Type" style="margin-bottom: 7px">
            <a-select v-model="announcementTypeName" :disabled="isLoadingAnnouncementPresets"
              :loading="isLoadingAnnouncementPresets" size="large" class="group-selector">
              <a-select-option v-for="preset in announcementPresets" :value="preset.displayName"
                option-label-prop="displayName" :key="preset.id">
                {{ preset.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-alert v-if="announcementTypeDescription" :message="announcementTypeDescription" type="info" show-icon />
        </div>
        <!-- / Announcement selector -->

        <!-- Guidance selector -->
        <select-guidance-modal :tenant-id="tenantIdForSelectedOrg" :org-id="selectedOrganisation.id"
          @set-guidance="setGuidance" :visible="selectGuidanceModalVisible"
          @close-modal="closeSelectGuidanceModal"></select-guidance-modal>

        <div v-if="isSharingGuidance" style="margin-top: 5px">
          <a-form-item label="Guidance" style="margin-bottom: 7px">
            <div class="guidance-pack-selector">
              <a-button @click.prevent="openSelectGuidanceModal">
                {{
                  selectedGuidancePackId ? "Change guidance" : "Select guidance"
                }}
              </a-button>
            </div>
          </a-form-item>
        </div>
        <!-- / Guidance selector -->

        <!-- Title -->
        <div style="margin-top: 5px">
          <a-form-item label="Title" style="margin-bottom: 7px">
            <a-input size="large" v-model="title"></a-input>
          </a-form-item>
        </div>
        <!-- / Title -->

        <!-- Description -->
        <div style="margin-top: 5px">
          <a-form-item label="Description" style="margin-bottom: 7px">
            <a-textarea size="large" v-model="description"></a-textarea>
          </a-form-item>
        </div>
        <!-- / Description -->

        <!-- Urgent -->
        <div class="urgent-wrapper">
          <span class="urgent-label">Urgent</span>
          <a-switch v-model="urgent" />
        </div>
        <!-- / Urgent -->

        <!-- Net support notify -->
        <div class="net-support-notify-wrapper" v-if="netSupportNotifyAvailable">
          <span class="net-support-notify-label">NetSupport Notify</span>
          <a-switch v-model="useNetSupportNotify" />
        </div>
        <!-- / Net support notify -->

        <!-- Tsos alert -->
        <div class="tsos-alert-wrapper" v-if="tsosAlertAvailable">
          <span class="tsos-alert-label">Send teamSOS Desktop Alert</span>
          <a-switch v-model="useTsosAlert" />
        </div>
        <!-- / Tsos alert -->

            <!-- Quick messages selector- dropdown button -->
            <div v-if="quickMessages && quickMessages.length" class="quick-messages-wrapper">
          <div class="quick-messages-scroll">
            <a-button 
              v-for="quickMessage in quickMessages" 
              :key="`quickMessage-${quickMessage.id}`"
              @click.prevent="quickCreate(quickMessage)"
              class="quick-message-button"
            >
              {{ quickMessage['displayName'] }}
            </a-button>
          </div>
        </div>
        <!-- / Quick messages selector -->
      </div>
      <!-- / Must have org selected -->
    </div>
    <!-- / Stage 0 -->

    <!-- Stage 1 -->
    <div class="inner" v-if="stage == 1">
      <p class="stage-1-first-p">
        This will be sent to {{ groupMembers.length }}
        {{ groupMembers.length == 1 ? "colleague" : "colleagues" }}.
      </p>
      <p>Colleagues can respond with {{ choicesFormatted }}.</p>
    </div>
    <!-- / Stage 1 -->
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import organisationHelpers from "../../helpers/organisations";
import announcements from "../../api/announcements";
import timelines from "../../api/timelines";
import quickMessages from "../../api/quick-messages";
const _ = require("lodash");
import chats from "../../api/chats";
import OrganisationThumbnail from "../Organisations/OrganisationThumbnail.vue";
import SelectGuidanceModal from "./SelectGuidanceModal.vue";
import axios from 'axios';

export default {
  components: { OrganisationThumbnail, SelectGuidanceModal },
  props: ["visible", "initialTitle", "orgId", "dontRedirect", "incidentId"],
  data() {
    return {
      stage: 0,

      selectedOrganisation: null,
      selectedGroupId: null,
      announcementTypeName: null,
      title: "",
      description: "",
      urgent: false,

      isSaving: false,

      isLoadingGroups: false,
      groups: [],

      isLoadingGroupMembers: false,
      groupMembers: [],

      isLoadingAnnouncementPresets: false,
      announcementPresets: [],

      isLoadingQuickMessages: false,
      quickMessages: [],

      quickCreateModalVisible: false,

      selectedGuidancePackId: null,
      selectedContentId: null,
      selectGuidanceModalVisible: null,

      isLoadingNetSupportNotifyFeature: false,
      netSupportNotifyAvailable: false,
      useNetSupportNotify: false,

      isLoadingTsosAlertFeature: false,
      tsosAlertAvailable: false,
      useTsosAlert: false
    };
  },
  created() {
    this.selectFirstAdminOrganisation();
  },
  watch: {
    selectedOrganisation(newVal) {
      this.selectedGroupId = null;
      this.netSupportNotifyAvailable = false;
      this.useNetSupportNotify = false;
      if (newVal) {
        this.loadGroupsForOrg();
        this.loadAnnouncementPresetsForOrg();
        this.determineIfNetSupportNotifyAvailable();
        this.determineIfTsosAlertAvailable();
        this.loadQuickMessages();
      }
    },
    visible(newVal) {
      if (newVal) {
        this.stage = 0;
        this.selectedOrganisation = null;
        this.selectedGroupId = null;
        // this.announcementTypeId = null;
        this.announcementTypeName = null;

        if (this.initialTitle && this.initialTitle.trim().length) {
          this.title = this.initialTitle;
        } else {
          this.title = "";
        }

        this.description = "";
        this.urgent = false;

        this.groups = [];

        if (this.orgId) {
          this.selectedOrganisation = _.find(this.organisations, {
            id: this.orgId,
          });
        } else {
          this.selectFirstAdminOrganisation();
        }
      }
    },
    selectedGroupId(newVal) {
      this.groupMembers = [];
      if (newVal) {
        this.loadGroupMembers();
      }
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor",
      organisationsIAmNotAnAdminFor: "organisationsIAmNotAnAdminFor",
      presences: "presences",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
    }),

    organisationsToShow() {
      if (this.orgId) {
        return [_.find(this.organisations, { id: this.orgId })];
      }
      return this.organisationsIAmAnAdminFor;
    },

    myPresenceId() {
      let myPresenceIds = _.map(this.presences, "id");
      let presenceIdsInOrganisation = _.map(this.groupMembers, "presenceId");
      let intersection = _.intersection(
        myPresenceIds,
        presenceIdsInOrganisation
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },

    groupDropdownOptions() {
      let options = _.map(this.groups, (group) => {
        return {
          id: group["id"],
          displayName: group["displayName"],
        };
      });
      return _.orderBy(options, "displayName");
    },

    announcementTypeId() {
      let announcementType = _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });
      return announcementType ? announcementType.id : null;
    },

    announcementTypeDescription() {
      if (this.announcementTypeId) {
        let announcementType = _.find(this.announcementPresets, {
          displayName: this.announcementTypeName,
        });
        return announcementType.description;
      }
      return null;
    },

    canAnnounce() {
      if (this.isSharingGuidance && !this.selectedGuidancePackId) {
        return false;
      }
      return (
        this.selectedGroupId !== null &&
        this.announcementTypeId !== null &&
        this.title &&
        this.title.length &&
        this.description &&
        this.description.length
      );
    },

    selectedAnnouncementType() {
      return _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });
    },

    choicesFormatted() {
      return _.map(this.selectedAnnouncementType.choices, "name").join(" or ");
    },

    isSharingGuidance() {
      return this.announcementTypeName == "Share Guidance to Staff";
    },

    tenantIdForSelectedOrg() {
      return this.getOrgTenantId(this.selectedOrganisation)
    },

    isLoading() {
      return (
        this.isLoadingNetSupportNotifyFeature ||
        this.isLoadingTsosAlertFeature ||
        this.isLoadingGroupMembers ||
        this.isLoadingGroupMembers ||
        this.isLoadingAnnouncementPresets
      )
    }
  },
  methods: {
    ...mapActions("announcements", {
      setExpectedNumberOfGroupMembers: "setExpectedNumberOfGroupMembers",
    }),

    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },

    selectOrgById(id) {
      this.selectedOrganisation = _.find(this.organisationsIAmAnAdminFor, {
        id: id,
      });
    },

    selectFirstAdminOrganisation() {
      if (
        this.organisationsIAmAnAdminFor.length &&
        !this.selectedOrganisation
      ) {
        this.selectedOrganisation = _.first(this.organisationsIAmAnAdminFor);
      }
    },

    loadGroupsForOrg() {
      let vm = this;
      vm.isLoadingGroups = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .getManagedChatsForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingGroups = false;
          vm.groups = r.data;
          vm.selectFirstGroup();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingGroups = false;
          // vm.$message.error("Error loading groups");
        });
    },

    selectFirstGroup() {
      if (this.groups.length && !this.selectedGroup) {
        let selectedGroup = _.first(this.groupDropdownOptions);
        this.selectedGroupId = selectedGroup ? selectedGroup["id"] : null;
      }
    },

    loadGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .getGroupMembersForChat(tenantId, this.selectedGroupId)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.groupMembers = r.data;
        })
        .catch((e) => {
          // vm.$message.error("Error loading group members");
          console.log(e);
        });
    },

    loadQuickMessages() {
      let vm = this;
      vm.isLoadingQuickMessages = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      quickMessages.getQuickMessagesForAnnouncements(tenantId, this.selectedOrganisation.id).then(r => {
        vm.isLoadingQuickMessages = false;
        vm.quickMessages = r.data;
      }).catch(e => {
        console.log(e);
        vm.isLoadingQuickMessages = false;
        vm.quickMessages = [];
      })
    },

    determineIfNetSupportNotifyAvailable() {
      let vm = this;
      vm.netSupportNotifyAvailable = false;
      vm.useNetSupportNotify = false;
      vm.isLoadingNetSupportNotifyFeature = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      axios.get(window.API_BASE + '/api/v1/' + tenantId + '/' + this.selectedOrganisation.id + '/feature/NetNotify').then(r => {
        if (r.data && Boolean(r.data) == true) {
          vm.netSupportNotifyAvailable = true;
        }
        vm.isLoadingNetSupportNotifyFeature = false;
      }).catch(e => {
        vm.isLoadingNetSupportNotifyFeature = false;
        console.log(e);
      })
    },

    determineIfTsosAlertAvailable() {
      let vm = this;
      vm.tsosAlertAvailable = false;
      vm.useTsosAlert = false;
      vm.isLoadingTsosAlertFeature = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      axios.get(window.API_BASE + '/api/v1/' + tenantId + '/' + this.selectedOrganisation.id + '/feature/TsosAlert').then(r => {
        if (r.data && Boolean(r.data) == true) {
          vm.tsosAlertAvailable = true;
        }
        vm.isLoadingTsosAlertFeature = false;
      }).catch(e => {
        vm.isLoadingTsosAlertFeature = false;
        console.log(e);
      })
    },

    loadAnnouncementPresetsForOrg() {
      let vm = this;
      vm.isLoadingAnnouncementPresets = true;
      vm.announcementPresets = [];

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      announcements
        .getAnnouncementPresetsForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingAnnouncementPresets = false;
          vm.announcementPresets = r.data;
          vm.selectFirstAnnouncementType();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingAnnouncementPresets = false;
          // vm.$message.error("Error loading announcement presets");
        });
    },

    selectFirstAnnouncementType() {
      if (this.announcementPresets.length && !this.announcementTypeId) {
        let announcementType = _.first(this.announcementPresets);
        this.announcementTypeId = announcementType
          ? announcementType["id"]
          : null;
      }
    },

    goToNextStage() {
      if (!this.canAnnounce) {
        return false;
      }
      this.stage += 1;
    },

    goBack() {
      if (this.isSaving) {
        return false;
      }
      this.stage -= 1;
    },

    openSelectGuidanceModal() {
      this.selectGuidanceModalVisible = true;
    },

    closeSelectGuidanceModal() {
      this.selectGuidanceModalVisible = false;
    },

    setGuidance(params) {
      let { guidancePackId, contentId, guidancePackName, contentName } = params;
      this.selectedGuidancePackId = guidancePackId;
      this.selectedContentId = contentId;

      if (contentName) {
        this.title = "Guidance: " + contentName;
      } else {
        this.title = "Guidance: " + guidancePackName;
      }

      this.description =
        'Please review this new or updated content in the "' +
        guidancePackName +
        '" guidance pack';

      this.selectGuidanceModalVisible = false;
    },

    persist() {
      let vm = this;
      vm.isSaving = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      let announcementType = _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });

      let announcementRequest = {
        OwnerId: this.selectedOrganisation.id,
        CreatedBy: this.myPresenceId,
        // CreatedBy: 'a19440e5-4396-413f-bcde-7c00a6fab633',
        DisplayName: this.title,
        Choices: announcementType.choices,
        Urgent: this.urgent,
      };

      // If incident id
      if (this.incidentId) {
        announcementRequest["IncidentId"] = this.incidentId;
      }

      if (this.netSupportNotifyAvailable && this.useNetSupportNotify) {
        announcementRequest['NetNotify'] = true;
        announcementRequest['netNotify'] = true;
      }

      if (this.tsosAlertAvailable && this.useTsosAlert) {
        announcementRequest['TeamsosAlert'] = true;
        announcementRequest['teamsosAlert'] = true;
      }

      announcements
        .addAnnouncement(tenantId, announcementRequest)
        .then((r) => {
          // console.log(r);
          vm.setExpectedNumberOfGroupMembers({
            announcementId: r.data.id,
            expectedNumberOfGroupMembers: this.groupMembers.length,
          });
          vm.postTimelineEntry(tenantId, r.data);
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating announcement");
        });
    },

    postTimelineEntry(tenantId, addAnnouncementResponse) {
      let vm = this;

      let timelineMessage;
      if (this.isSharingGuidance) {
        let dollarV = {
          Text: vm.description,
          GuidancePackId: vm.selectedGuidancePackId,
        };
        if (vm.selectedContentId) {
          dollarV["ContentId"] = vm.selectedContentId;
        }

        timelineMessage = {
          OwnerType: 3,
          OwnerId: addAnnouncementResponse.id,
          Behaviour: 1,
          CreatedByPresenceId: this.myPresenceId,
          //CreatedByPresenceId: 'a19440e5-4396-413f-bcde-7c00a6fab633',
          Content: {
            $t: "Soteria.Models.GuidanceChatEntry, Soteria.Models",
            $v: dollarV,
          },
        };
      } else {
        timelineMessage = {
          OwnerType: 3,
          OwnerId: addAnnouncementResponse.id,
          Behaviour: 1,
          CreatedByPresenceId: this.myPresenceId,
          // CreatedByPresenceId: 'a19440e5-4396-413f-bcde-7c00a6fab633',
          Content: {
            $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
            $v: {
              Text: vm.description,
            },
          },
        };
      }

      timelines
        .postTimelineEntry(tenantId, timelineMessage)
        .then(() => {
          vm.confirmAnnouncement(tenantId, addAnnouncementResponse.id);
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating timeline entry");
        });
    },

    confirmAnnouncement(tenantId, announcementId) {
      let vm = this;
      let confirmAnnouncementRequest = {
        AnnouncementId: announcementId,
        Members: null,
        ManagedChats: [this.selectedGroupId],
        Admins: null,
      };
      announcements
        .confirmAnnouncement(tenantId, confirmAnnouncementRequest)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Announcement created successfully");

          if (vm.dontRedirect) {
            vm.$emit("close-modal");
          } else {
            vm.$router.push("/announcements/" + tenantId + "/" + announcementId);
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error confirming announcement");
        });
    },

    openQuickCreateModal() {
      this.quickCreateModalVisible = true;
    },

    closeQuickCreateModal() {
      this.quickCreateModalVisible = false;
    },

    quickCreate(quickMessage) {
      // Handle AppendOnly attribute if it exists
      if (quickMessage.attributes?.AppendOnly === "true" || quickMessage.attributes?.AppendOnly === true) {
        this.title = (this.title ? this.title + " - " : "") + quickMessage['displayName'];
        this.description = (this.description ? this.description + "\n\n" : "") + quickMessage['body'];
      } else {
        // Original behavior
        this.title = quickMessage['displayName'];
        this.description = quickMessage['body'];
      }

      // Handle quick message attributes if they exist
      if (quickMessage.attributes) {
        // Set urgent flag
        if (quickMessage.attributes.IsUrgent === "true") {
          this.urgent = true;
        }

        // Set desktop alert (TSOS)
        if (quickMessage.attributes.DesktopAlert === "true" && this.tsosAlertAvailable) {
          this.useTsosAlert = true;
        }

        if (quickMessage.attributes.NetNotify === "true" && this.netSupportNotifyAvailable) {
          this.useNetSupportNotify = true;
        }

        // Set group if specified
        if (quickMessage.attributes.ToManagedGroupId) {
          this.selectedGroupId = quickMessage.attributes.ToManagedGroupId;
        }

        // Set announcement type if specified
        if (quickMessage.attributes.PresetId) {
          const preset = this.announcementPresets.find(p => p.id === quickMessage.attributes.PresetId);
          if (preset) {
            this.announcementTypeName = preset.displayName;
          }
        }

        // If AutoSend is true, automatically go to next stage
        if (quickMessage.attributes.AutoSend === "true") {
          this.$nextTick(() => {
            if (this.canAnnounce) {
              this.goToNextStage();
            }
          });
        }
      }

      this.closeQuickCreateModal();
    }
  },
};
</script>

<style lang="scss">
.create-announcement-modal {
  .ant-modal-body {
    padding: 0;
  }

  .org-thumbnail {
    display: inline;
    margin-right: 5px;
  }

  .inner {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 20px;
  }

  .group-selector {
    width: 100%;
  }

  .urgent-wrapper {
    display: flex;
    display: block;
    margin-top: 15px;

    .urgent-label {
      margin-right: 10px;
    }
  }

  .net-support-notify-wrapper {
    display: flex;
    display: block;
    margin-top: 15px;

    .net-support-notify-label {
      margin-right: 10px;
    }
  }

  .tsos-alert-wrapper {
    display: flex;
    display: block;
    margin-top: 15px;

    .tsos-alert-label {
      margin-right: 10px;
    }
  }

  .stage-1-first-p {
    margin-top: 25px;
  }

  .hidden {
    display: none;
  }

  .loader {
    padding: 50px;
    text-align: center;
  }

  .quick-create-button-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .quick-messages-wrapper {
    width: 100%;
    overflow: hidden;
    margin-top: 30px;

    .quick-messages-scroll {
      display: flex;
      overflow-x: auto;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
    }

    .quick-message-button {
      flex-shrink: 0;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.quick-create-modal {
  .quick-create-option {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>