<template>
  <div>
    <a-form-item :label="control['$v']['label']"  :required="isRequired">
      <a-select 
        v-model="params[control['$v']['name']]"
        style="width: 100%"
        :disabled="isLoading"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        size="large"
        allowClear
      >
        <a-select-option
          :value="option.linkTargetLookupId"
          v-for="(option, i) in options"
          :key="i"
        >
          {{ option.displayName }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </div>
</template>

<script>
const _ = require("lodash");
import apiClients from "../../../../../../../api/api-clients";
export default {
  props: {
    params: Object,
    control: Object,
    tenantId: String,
    orgId: String,
    apiClientId: String,
    incidentTags: Array
  },

  watch: {
    options: {
      handler(newOptions) {
        if (newOptions && this.incidentTags) {
          this.preselectFromTags();
        }
      }
    }
  },

  data() {
    return {
      isLoading: false,
      apiClientLookups: [],
    };
  },
  computed: {
    options() {
      return _.filter(this.apiClientLookups, (lookup) => {
        return lookup.linkTargetLookupType == this.control["$v"]["lookupName"];
      });
    },
    isRequired() {
      return (
        this.control["$v"]["required"] && this.control["$v"]["required"] == true
      );
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      let vm = this;
      vm.isLoading = true;
      apiClients
        .getApiClientLookupsForOrg(
          this.tenantId,
          this.orgId,
          this.apiClientId,
          "77c3a97e-46ef-45c6-bfcb-988ac47e9927"
        )
        .then((r) => {
          vm.isLoading = false;
          vm.apiClientLookups = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading options");
        });
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    preselectFromTags() {
      if (!this.incidentTags || !this.options) return;

      // Find matching tag and option
      const matchingTag = this.incidentTags.find(tag => 
        this.options.some(option => option.linkTargetLookupId === tag.providersId)
      );

      if (matchingTag) {
        // Set the matching option
        this.params[this.control['$v']['name']] = matchingTag.providersId;
      }
    },
  },
};
</script>

<style>
</style>