<template>
  <div
    class="incident-type-option"
    :class="{
      lightspeed: lightspeed == true,
      sims: sims == true,
      'in-widget': inWidget == true,
    }"
    @click.prevent="handleOptionSelected"
  >
    <div class="inner-wrapper">
      <div class="left">
        {{ incidentType.displayName }}
      </div>
      <div class="right">
        <span class="sos" v-if="sos">SOS</span>
        <span v-else>{{ categoryDisplayName }}</span>
      </div>
    </div>

    <a-modal
      v-model="modalVisible"
      :title="incidentType.displayName"
      @ok="attemptCreateIncident"
      :confirm-loading="isSaving"
      ok-text="Start Incident"
      :ok-button-props="{ props: { disabled: !canCreateIncident } }"
    >
      <a-form-item>
        <a-input
          v-model="incidentTitle"
          placeholder="Incident Title"
          size="large"
        ></a-input>
      </a-form-item>

      <a-form-item label="Location (Optional)">
        <div class="location-tags-wrapper">
          <a-select
            style="width: 100%"
            v-model="selectedLocationTagId"
            placeholder="Select a location (optional)"
            :loading="isLoadingLocationTags"
            :is-loading="isLoadingLocationTags"
            size="large"
            allowClear
          >
            <!-- Pinned Locations Group -->
            <a-select-opt-group
              v-if="pinnedLocationTags.length"
              label="Pinned Locations"
            >
              <a-select-option
                v-for="tag in pinnedLocationTags"
                :key="tag.providersId"
                :value="tag.providersId"
              >
                <div class="location-option">
                  <span>{{ tag.description }}</span>
                  <a-icon
                    type="pushpin"
                    theme="filled"
                    class="pin-icon pinned"
                    @click.stop="togglePinLocation(tag)"
                  />
                </div>
              </a-select-option>
            </a-select-opt-group>

            <!-- Other Locations Group -->
            <a-select-opt-group
              :label="pinnedLocationTags.length ? 'Other Locations' : 'Locations'"
            >
              <a-select-option
                v-for="tag in unpinnedLocationTags"
                :key="tag.providersId"
                :value="tag.providersId"
              >
                <div class="location-option">
                  <span>{{ tag.description }}</span>
                  <a-icon
                    type="pushpin"
                    class="pin-icon"
                    @click.stop="togglePinLocation(tag)"
                  />
                </div>
              </a-select-option>
            </a-select-opt-group>
          </a-select>
          <a-button class="tags-button" @click="openTagsModal">
            Tags ({{ nonLocationTagsCount }})
          </a-button>
        </div>
      </a-form-item>

      <a-form-item label="Notes">
        <a-textarea
          v-model="incidentDescription"
          placeholder="Enter required incident notes"
          rows="6"
        ></a-textarea>
      </a-form-item>

      <div class="quick-messages-wrapper">
        <div class="quick-messages-scroll">
          <a-button
            v-for="message in quickMessages"
            :key="message.id"
            class="quick-message-button"
            @click="selectQuickMessage(message)"
          >
            {{ message.displayName }}
          </a-button>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="sosModalVisible"
      :title="incidentType.displayName"
      ok-text="Send Now"
      @ok="createSOS"
    >
      <div class="sos-modal-inner">
        <h3>
          We're about to alert the {{ isDemo ? "Demo" : "SOS" }} responders
        </h3>

        <div
          class="seconds-until-trigger-wrapper"
          :style="{ '--p': (100 * secondsUntilTrigger) / 6 }"
        >
          <div class="seconds-until-trigger">
            <div>
              {{ secondsUntilTrigger }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="appendConfirmModalVisible"
      title="Quick Message Options"
      :footer="null"
      @cancel="appendConfirmModalVisible = false"
    >
      <div class="quick-message-options">
        <p>How would you like to add this quick message?</p>
        <div class="button-group">
          <a-button
            type="primary"
            @click="processQuickMessage(pendingQuickMessage, true)"
          >
            Append to existing content
          </a-button>
          <a-button @click="processQuickMessage(pendingQuickMessage, false)">
            Replace existing content
          </a-button>
        </div>
      </div>
    </a-modal>

    <!-- Add new Tags Modal -->
    <a-modal
      v-model="tagsModalVisible"
      title="Select Tags"
      :footer="null"
      @cancel="closeTagsModal"
    >
      <div class="tags-modal-content">
        <!-- Search Section -->
        <div class="search-section">
          <label>Search for a tag (at least 3 characters)</label>
          <a-select
            style="width: 100%"
            show-search
            mode="multiple"
            :filter-option="false"
            :loading="isLoadingTags"
            :is-loading="isLoadingTags"
            size="large"
            :value="visibleSelectedTags.map(tag => tag.providersId)"
            placeholder="Search for tags"
            @search="debouncedSearchTags"
            @select="handleTagSelect"
            @deselect="handleTagDeselect"
          >
            <!-- Selected Tags Group -->
            <a-select-opt-group
              v-if="visibleSelectedTags.length"
              label="Selected Tags"
            >
              <a-select-option
                v-for="tag in visibleSelectedTags"
                :key="tag.providersId"
                :value="tag.providersId"
              >
                <div class="tag-option">
                  <div class="tag-details">
                    <div class="tag-description">{{ tag.description }}</div>
                    <div class="tag-subtext">{{ tag.provider }} - {{ tag.type }}</div>
                  </div>
                </div>
              </a-select-option>
            </a-select-opt-group>

            <!-- Search Results Group -->
            <a-select-opt-group
              v-if="searchResults.length"
              :label="visibleSelectedTags.length ? 'Found Tags' : 'Tags'"
            >
              <a-select-option
                v-for="tag in searchResults"
                :key="tag.providersId"
                :value="tag.providersId"
              >
                <div class="tag-option">
                  <div class="tag-details">
                    <div class="tag-description">{{ tag.description }}</div>
                    <div class="tag-subtext">{{ tag.provider }} - {{ tag.type }}</div>
                  </div>
                </div>
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </div>
      </div>
    </a-modal>

    <!-- Select tag of type modal -->
    <a-modal
      v-model="selectTagOfTypeModalVisible"
      :title="`Select ${tagType} Tags`"
      @ok="selectTagOfTypeModalOk"
      :confirm-loading="false"
    >
      <div class="search-section">
        <label>Search for a {{ tagType }} tag (at least 3 characters)</label>
        <a-select
          style="width: 100%"
          show-search
          mode="multiple"
          :filter-option="false"
          :loading="isLoadingTagsOfType"
          :is-loading="isLoadingTagsOfType"
          size="large"
          :value="selectedTagsOfType.map(tag => tag.providersId)"
          placeholder="Search for tags"
          @search="debouncedSearchTagsOfType"
          @select="handleTagOfTypeSelect"
          @deselect="handleTagOfTypeDeselect"
        >
          <!-- Selected Tags Group -->
          <a-select-opt-group
            v-if="selectedTagsOfType.length"
            label="Selected Tags"
          >
            <a-select-option
              v-for="tag in selectedTagsOfType"
              :key="tag.providersId"
              :value="tag.providersId"
            >
              <div class="tag-option">
                <div class="tag-details">
                  <div class="tag-description">{{ tag.description }}</div>
                  <div class="tag-subtext">{{ tag.provider }} - {{ tag.type }}</div>
                </div>
              </div>
            </a-select-option>
          </a-select-opt-group>

          <!-- Search Results Group -->
          <a-select-opt-group
            v-if="availableTagsOfType.length"
            :label="selectedTagsOfType.length ? 'Found Tags' : 'Tags'"
          >
            <a-select-option
              v-for="tag in availableTagsOfType"
              :key="tag.providersId"
              :value="tag.providersId"
            >
              <div class="tag-option">
                <div class="tag-details">
                  <div class="tag-description">{{ tag.description }}</div>
                  <div class="tag-subtext">{{ tag.provider }} - {{ tag.type }}</div>
                </div>
              </div>
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>
<script>
import incidentTypes from "../../../api/incident-types";
import organisations from "../../../helpers/organisations";
import { mapGetters, mapActions } from "vuex";
import tags from "../../../api/tags";
import quickMessages from "../../../api/quick-messages";
const _ = require("lodash");

export default {
  props: [
    "incidentType",
    "sos",
    "categories",
    "lightspeed",
    "sims",
    "inWidget",
  ],
  data() {
    return {
      selectTagOfTypeModalVisible: false,
      selectTagOfTypeModalOk: null,
      tagType: null,
      selectedTagsOfType: [],

      fleshed: null,
      isLoading: false,

      modalVisible: false,
      incidentDescription: "",
      incidentTitle: "",
      // isDemo: false,

      sosModalVisible: false,
      secondsUntilTrigger: 6,
      triggerIntervalFunction: null,
      locationTags: [],
      isLoadingLocationTags: false,
      quickMessages: [],
      selectedQuickMessage: null,
      appendConfirmModalVisible: false,
      pendingQuickMessage: null,
      tagsModalVisible: false,
      selectedTags: [],
      searchResults: [],
      isLoadingTags: false,
      isLoadingTagsOfType: false,
      availableTagsOfType: [],
      selectedTagIds: [],
      debouncedSearchTagsOfType: null,
      selectedLocationTagId: null,
    };
  },
  watch: {
    sosModalVisible(newVal) {
      if (newVal) {
        let vm = this;
        window.clearInterval(this.triggerIntervalFunction);
        this.triggerIntervalFunction = null;

        this.triggerIntervalFunction = window.setInterval(function () {
          vm.secondsUntilTrigger--;
          if (vm.secondsUntilTrigger == 0) {
            window.clearInterval(vm.triggerIntervalFunction);
            vm.triggerIntervalFunction = null;
            vm.createSOS();
          }
        }, 1000);
      } else {
        window.clearInterval(this.triggerIntervalFunction);
        this.triggerIntervalFunction = null;
      }
    },

    selectedLocationTagId(newValue) {
      if (newValue) {
        const locationTag = this.locationTags.find(tag => tag.providersId === newValue);
        if (locationTag) {
          this.selectedLocationTag = locationTag;
          this.incidentTitle = `${this.incidentType.displayName} (${locationTag.description})`;
        }
      } else {
        this.selectedLocationTag = null;
        this.incidentTitle = `${this.incidentType.displayName} (${this.userDisplayName})`;
      }
    }
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      userDisplayName: "userDisplayName",
    }),

    ...mapGetters("helpCaller", {
      selectedOrganisation: "selectedOrganisation",
      isSaving: "isSaving",
      isDemo: "isDemo",
      numberOfIncidentTypesShown: "numberOfIncidentTypesShown",
    }),

    ...mapGetters("pinned", {
      pinnedLocationIds: "pinnedLocationIds"
    }),

    canCreateIncident() {
      return (
        this.incidentDescription.trim().length &&
        this.incidentTitle.trim().length
      );
    },

    categoryDisplayName() {
      let category = _.find(this.categories, {
        id: this.incidentType.incidentTypeGroupId,
      });
      if (category) {
        return category.displayName;
      }
      return "-";
    },

    pinnedLocationTags() {
      return this.locationTags.filter((tag) =>
        this.pinnedLocationIds.includes(tag.providersId)
      );
    },

    unpinnedLocationTags() {
      return this.locationTags.filter(
        (tag) => !this.pinnedLocationIds.includes(tag.providersId)
      );
    },

    selectedLocationTag: {
      get() {
        return this.selectedTags.find(tag => tag.type === 'Location');
      },
      set(newTag) {
        // Remove any existing location tags
        this.selectedTags = this.selectedTags.filter(tag => tag.type !== 'Location');
        // Add the new location tag if one was selected
        if (newTag) {
          this.selectedTags.push(newTag);
        }
      }
    },

    nonLocationTagsCount() {
      return this.selectedTags.filter(tag => tag.type !== 'Location').length;
    },

    visibleSelectedTags() {
      return this.selectedTags.filter(tag => tag.type !== 'Location');
    },

    dropdownTags() {
      return {
        selected: this.selectedTags,
        found: this.searchResults
      };
    }
  },
  created() {
    if (
      this.organisations.length == 1 &&
      this.numberOfIncidentTypesShown == 1
    ) {
      this.handleOptionSelected();
    }
    // this.flesh()
    this.debouncedSearchTagsOfType = _.debounce(this.searchTagsOfType, 300);
  },
  methods: {
    ...mapActions("helpCaller", {
      callForHelp: "callForHelp",
    }),

    ...mapActions("pinned", {
      togglePinnedLocation: "togglePinnedLocation"
    }),

    flesh() {
      let vm = this;
      vm.isLoading = true;
      incidentTypes
        .getIncidentType(
          organisations.getOrganisationTenantId(this.selectedOrganisation),
          this.incidentType.id
        )
        .then((r) => {
          vm.fleshed = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          // vm.$message.error("Error loading incident types");
          console.log(e);
          vm.fleshed = null;
          vm.isLoading = false;
        });
    },

    handleOptionSelected() {
      if (this.sos) {
        this.openSosModal();
      } else {
        this.openModal();
      }
    },

    openSosModal() {
      this.secondsUntilTrigger = 6;
      this.sosModalVisible = true;
    },

    openModal() {
      this.modalVisible = true;
      this.loadLocationTags();
      this.loadQuickMessages();
      // Set initial title without location
      this.incidentTitle = `${this.incidentType.displayName} (${this.userDisplayName})`;
    },

    attemptCreateIncident() {
      if (!this.canCreateIncident) {
        return false;
      }

      this.callForHelp({
        title: this.incidentTitle,
        description: this.incidentDescription,
        incidentType: this.incidentType,
        isDemo: this.isDemo,
        lightspeed: this.lightspeed == true,
        sims: this.sims == true,
        tags: this.selectedTags, // No need to add location tag separately
      });
    },

    createSOS() {
      window.clearInterval(this.triggerIntervalFunction);
      this.triggerIntervalFunction = null;

      this.callForHelp({
        description: this.incidentType.displayName,
        incidentType: this.incidentType,
        isDemo: this.isDemo,
        lightspeed: this.lightspeed == true,
        sims: this.sims == true,
      });
    },

    extractTagTypes(template) {
      const tagRegex = /\[(.*?)\]/g;
      const matches = [...template.matchAll(tagRegex)];
      return matches.map(match => match[1]);
    },

    getTagsByType(tags, type) {
      return tags.filter(tag => tag.type === type);
    },

    openTagSelectionModal(tagType) {
      this.selectedTagIds = [];
      this.availableTagsOfType = [];
      this.selectTagOfTypeModalVisible = true;
      this.tagType = tagType;

      // wait for the ok button to be clicked
      return new Promise((resolve) => {
        this.selectTagOfTypeModalOk = () => {
          resolve(Object.assign([], this.selectedTagsOfType));
          this.selectTagOfTypeModalVisible = false;
          this.selectedTagsOfType = [];
          this.availableTagsOfType = [];
        };
      });
    },

    async processTemplate(template, selectedTags) {
      let processedText = template;
      const tagTypes = this.extractTagTypes(template);
      
      // Track missing tag types that need to be selected
      let missingTagTypes = [];
      
      // First pass - check which tag types are missing
      for (const tagType of tagTypes) {
        const existingTags = this.getTagsByType(selectedTags, tagType);
        if (existingTags.length === 0) {
          missingTagTypes.push(tagType);
        }
      }
      
      // If there are missing tags, handle them sequentially
      if (missingTagTypes.length > 0) {
        for (const tagType of missingTagTypes) {
          // Open tag selection modal for this type
          const selectedTagsOfType = await this.openTagSelectionModal(tagType);
         
          // Check if the modal was closed without selecting any tags
          if (selectedTagsOfType.length === 0) {
            missingTagTypes.push(tagType);
          } else {
            for (const tag of selectedTagsOfType) {
              selectedTags.push(tag);
              if (tag.type === 'Location') {
                this.selectedLocationTagId = tag.providersId;
              }
            }
            missingTagTypes = missingTagTypes.filter(t => t !== tagType);
          }
        }
      }
      
      // Replace all placeholders with tag descriptions
      for (const tagType of tagTypes) {
        const tagsOfType = this.getTagsByType(selectedTags, tagType);
        const descriptions = tagsOfType.map(tag => tag.description).join(', ');
        processedText = processedText.replace(`[${tagType}]`, descriptions);
      }
      
      return processedText;
    },

    loadLocationTags() {
      this.isLoadingLocationTags = true;
      tags
        .getTags(
          organisations.getOrganisationTenantId(this.selectedOrganisation),
          this.selectedOrganisation.id,
          null, // provider
          null, // containing
          "Location" // type
        )
        .then((response) => {
          this.locationTags = response.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("Error loading location tags");
        })
        .finally(() => {
          this.isLoadingLocationTags = false;
        });
    },

    loadQuickMessages() {
      quickMessages
        .getQuickMessages(
          organisations.getOrganisationTenantId(this.selectedOrganisation),
          this.selectedOrganisation.id,
          false,
          "NewIncident"
        )
        .then((response) => {
          // Filter for NewIncident type messages and match IncidentTypeId if present
          this.quickMessages = response.data.filter((msg) => {
            // First check if it's a NewIncident type
            if (msg.type !== "NewIncident") return false;

            // If message has IncidentTypeId attribute, check if it matches
            if (msg.attributes?.IncidentTypeId) {
              return msg.attributes.IncidentTypeId === this.incidentType.id;
            }

            // Include messages without IncidentTypeId attribute
            return true;
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("Error loading quick messages");
          this.quickMessages = [];
        });
    },

    selectQuickMessage(message) {
      // Store the selected quick message first
      this.selectedQuickMessage = message;

      // If there's no existing content or it's AppendOnly, process immediately
      if (
        !this.incidentDescription ||
        message.attributes?.AppendOnly === "true" ||
        message.attributes?.AppendOnly === true
      ) {
        this.processQuickMessage(message, true); // true for append
      } else {
        // Show confirmation modal for non-AppendOnly messages when there's existing content
        this.pendingQuickMessage = message;
        this.appendConfirmModalVisible = true;
      }
    },

    async processQuickMessage(message, shouldAppend) {
      this.appendConfirmModalVisible = false;

      let messageBody = message.body;
  
      // Process template if it contains tag placeholders
      if (messageBody.includes('[')) {
        messageBody = await this.processTemplate(messageBody, this.selectedTags);
      }
      
      if (shouldAppend && this.incidentDescription) {
        this.incidentDescription = 
          this.incidentDescription.trim() + "\n\n" + messageBody;
      } else {
        this.incidentDescription = messageBody;
      }
      
      this.appendConfirmModalVisible = false;
      this.pendingQuickMessage = null;
    },

    togglePinLocation(tag) {
      this.togglePinnedLocation(tag.providersId);
    },

    openTagsModal() {
      this.tagsModalVisible = true;
    },

    closeTagsModal() {
      this.tagsModalVisible = false;
      this.searchResults = [];
    },

    toggleTag(tag) {
      const index = this.selectedTags.findIndex(
        (t) => t.providersId === tag.providersId
      );
      if (index === -1) {
        this.selectedTags.push(tag);
        // Remove from search results
        this.searchResults = this.searchResults.filter(
          (t) => t.providersId !== tag.providersId
        );
      }
    },

    removeTag(tag) {
      const index = this.selectedTags.findIndex(
        (t) => t.providersId === tag.providersId
      );
      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    },

    isTagSelected(tag) {
      return this.selectedTags.some((t) => t.providersId === tag.providersId);
    },

    debouncedSearchTags: _.debounce(function (query) {
      // Always show selected tags, regardless of search query
      if (!query || query.length < 3) {
        this.searchResults = [];
        return;
      }

      this.isLoadingTags = true;
      tags
        .getTags(
          organisations.getOrganisationTenantId(this.selectedOrganisation),
          this.selectedOrganisation.id,
          null, // provider
          query, // containing
          null // type
        )
        .then((response) => {
          // Filter out already selected tags from search results
          this.searchResults = response.data.filter(
            (tag) =>
              !this.selectedTags.some(
                (selected) => selected.providersId === tag.providersId
              )
          );
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("Error searching tags");
          this.searchResults = [];
        })
        .finally(() => {
          this.isLoadingTags = false;
        });
    }, 300),

    handleTagDeselect(value) {
      const tag = this.selectedTags.find(t => t.providersId === value);
      if (tag) {
        if (tag.type === 'Location') {
          this.selectedLocationTagId = null;
        }
        this.removeTag(tag);
      }
    },

    handleTagSelect(value) {
      const tag = [...this.searchResults, ...this.selectedTags].find(
        t => t.providersId === value
      );
      if (tag && !this.isTagSelected(tag)) {
        // If it's a location tag, update the location dropdown
        if (tag.type === 'Location') {
          this.selectedLocationTagId = tag.providersId;
        } else {
          this.toggleTag(tag);
        }
      }
    },

    async searchTagsOfType(search) {
      if (!search || search.length < 3) {
        this.availableTagsOfType = [];
        return;
      }

      this.isLoadingTagsOfType = true;
      try {
        const response = await tags.getTags(
          organisations.getOrganisationTenantId(this.selectedOrganisation),
          this.selectedOrganisation.id,
          null,
          search,
          this.tagType
        );
        // Filter out already selected tags
        this.availableTagsOfType = response.data.filter(
          tag => !this.selectedTagsOfType.some(
            selected => selected.providersId === tag.providersId
          )
        );
      } catch (e) {
        console.log(e);
        this.$message.error("Error searching tags");
        this.availableTagsOfType = [];
      } finally {
        this.isLoadingTagsOfType = false;
      }
    },

    handleTagOfTypeSelect(value) {
      const tag = [...this.availableTagsOfType, ...this.selectedTagsOfType].find(
        t => t.providersId === value
      );
      if (tag && !this.selectedTagsOfType.some(t => t.providersId === tag.providersId)) {
        this.selectedTagsOfType.push(tag);
        // Remove from available tags
        this.availableTagsOfType = this.availableTagsOfType.filter(
          t => t.providersId !== tag.providersId
        );
      }
    },

    handleTagOfTypeDeselect(value) {
      const tag = this.selectedTagsOfType.find(t => t.providersId === value);
      if (tag) {
        this.selectedTagsOfType = this.selectedTagsOfType.filter(
          t => t.providersId !== tag.providersId
        );
      }
    }
  },
};
</script>

<style scoped lang="scss">
.incident-type-option {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #fff;

  &.in-widget {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.lightspeed {
    border: 1px solid #eee;

    &:hover {
      color: #3398c6 !important;
      border: 1px solid #3398c6 !important;
    }
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}

.inner-wrapper {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}

.modal-toolbar {
  display: flex;
  display: block;
  margin-top: 15px;

  .is-demo-label {
    margin-right: 10px;
  }
}

.sos {
  color: #00c0b2;
  font-weight: bold;
}

.sos-modal-inner {
  text-align: center;
}

.seconds-until-trigger-wrapper {
  margin-top: 40px;
  margin-bottom: 35px;
  font-size: 100px;
  padding: 7px;
  display: inline-block;
  // background: #5155ea;
  border-radius: 50%;
  position: relative;

  --c: #5155ea;
  --p: 100;
  --b: 5px;

  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    z-index: 1000;
    background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);

    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--b)),
      #000 calc(100% - var(--b))
    );
    mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--b)),
      #000 calc(100% - var(--b))
    );
  }

  .seconds-until-trigger {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    color: #5155ea;
    z-index: 1001;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.quick-messages-wrapper {
  width: 100%;
  overflow: hidden;

  .quick-messages-scroll {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
  }

  .quick-message-button {
    flex: 0 0 auto;
    margin-right: 8px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}

.quick-message-options {
  text-align: center;

  .button-group {
    margin-top: 20px;

    .ant-btn {
      margin: 0 8px;
    }
  }
}

.location-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pin-icon {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &.pinned {
      opacity: 1;
      color: #5155ea;
    }
  }
}

// Style for the group labels
:deep(.ant-select-dropdown) {
  .ant-select-dropdown-menu-item-group-title {
    font-weight: 500;
    color: #666;
    padding-top: 12px;
  }
}

.location-tags-wrapper {
  display: flex;
  gap: 8px;

  .ant-select {
    flex-grow: 1;
  }

  .tags-button {
    flex-shrink: 0;
    height: 40px !important;
  }
}

.tags-modal-content {
  .selected-tags {
    margin-bottom: 20px;

    .section-label {
      font-weight: 500;
      margin-bottom: 8px;
      color: #666;
    }

    .ant-tag {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }

  .search-section {
    label {
      display: block;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.tag-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tag-details {
    flex-grow: 1;
  }

  .tag-description {
    font-size: 14px;
  }

  .tag-subtext {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  .tag-action-icon {
    color: #1890ff;
  }
}
</style>