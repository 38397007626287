<template>
  <div class="admin-create-quick-incident-response">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <div class="page-header">
      <h1 class="page-title">Create Quick Incident Response</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>

    <div class="form">
      <a-form-item label="Title">
        <a-input
          size="large"
          v-model="quickMessage.displayName"
        ></a-input>
      </a-form-item>

      <a-form-item label="Notes">
        <a-textarea
          :rows="6"
          size="large"
          v-model="quickMessage.body"
        ></a-textarea>
      </a-form-item>

      <a-form-item label="Incident Type">
        <a-select
          style="width: 100%"
          v-model="quickMessage.attributes.IncidentTypeId"
          placeholder="Select incident type (optional)"
          size="large"
          allowClear
        >
          <a-select-option
            v-for="type in incidentTypes"
            :key="type.id"
            :value="type.id"
          >
            {{ type.displayName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Behavior">
        <a-radio-group v-model="quickMessage.attributes.AppendOnly">
          <a-radio :value="false">Replace existing content</a-radio>
          <a-radio :value="true">Append to existing content</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="quickMessage.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import quickMessages from "../../../../api/quick-messages";
import incidentTypes from "../../../../api/incident-types";

export default {
  data() {
    return {
      isSaving: false,
      incidentTypes: [],
      quickMessage: {
        displayName: "",
        body: "",
        scope: 1,
        attributes: {
          AppendOnly: false,
          IncidentTypeId: null
        }
      }
    };
  },

  components: { LoadingScreen },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },

  methods: {
    goBack() {
      this.$router.push("/admin/settings/quick-incident-responses");
    },

    loadIncidentTypes() {
      let vm = this;
      incidentTypes
        .getIncidentTypes(this.tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.incidentTypes = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error loading incident types");
        });
    },

    getValidationErrors() {
      let errors = [];
      if (this.quickMessage.displayName.trim().length == 0) {
        errors.push("Please provide a title");
      }
      if (this.quickMessage.body.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      let params = {
        ...this.quickMessage,
        ownerId: this.selectedOrganisation.id,
        purpose: 'NewIncident',
        type: 'NewIncident',
        attributes: {
          ...this.quickMessage.attributes,
          AppendOnly: this.quickMessage.attributes.AppendOnly.toString()
        }
      };

      vm.isSaving = true;

      try {
        await quickMessages.addQuickMessage(this.tenantId, params);
        vm.isSaving = false;
        vm.$message.success("Quick incident response created successfully");
        vm.$router.push("/admin/settings/quick-incident-responses");
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error("Error creating quick incident response");
      }
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadIncidentTypes();
    }
  },
};
</script>

<style scoped lang="scss">
.admin-create-quick-incident-response {
  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }

  padding-bottom: 30px;
}
</style>
