<template>
  <div class="admin-announcement-templates">
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings/templates')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings - Announcement Templates
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search announcement templates"
          v-model="searchQuery"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadQuickMessages"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createQuickMessage"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>

    <!-- <a-alert style="margin-bottom: 25px" -->
     <!-- message="Annou"> -->
    <!-- </a-alert> -->

    <!-- Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- No results -->
      <a-alert
        v-if="!isLoading && quickMessagesToShow.length == 0"
        type="info"
        message="No announcement templates to show"
      >
      </a-alert>
      <!-- / No results -->

      <!-- Results -->
      <div
        class="quick-messages-list"
        v-if="!isLoading && quickMessagesToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="quickMessage in quickMessagesToShow"
            :key="quickMessage.id"
          >
            <quick-message-list-item
              :quick-message="quickMessage"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToQuickMessage(quickMessage)"
            >
            </quick-message-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Results -->
    </div>
    <!-- / Loaded- -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import quickMessages from "../../../api/quick-messages";
import QuickMessageListItem from "./QuickMessages/QuickMessageListItem.vue";
const _ = require('lodash');

export default {
  components: { QuickMessageListItem },

  data() {
    return {
      isLoading: false,
      quickMessages: [],
      searchQuery: "",
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    quickMessagesToShow() {
      let filtered = _.filter(this.quickMessages, (quickMessages) => {
        if (this.searchQuery.trim().length) {
          return quickMessages.displayName
            .toLowerCase()
            .includes(this.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
      return _.orderBy(filtered, "displayName");
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadQuickMessages();
  },

  methods: {
    loadQuickMessages() {
      let vm = this;
      vm.isLoading = true;
      vm.quickMessages = [];
      quickMessages
        .getQuickMessagesForAnnouncements(vm.tenantId, vm.selectedOrganisation.id, true)
        .then((r) => {
          vm.isLoading = false;
          vm.quickMessages = r.data;
        })
        .catch((e) => {
          vm.isLoading = false;
          vm.$message.error("Error loading announcement templates");
          console.log(e);
        });
    },

    createQuickMessage() {
      this.$router.push("/admin/settings/announcement-templates/create");
    },

    navigateToQuickMessage(quickMessage) {
      this.$router.push("/admin/settings/announcement-templates/" + quickMessage.id + "/edit");
    },
  },
};
</script>

<style scoped lang="scss">
.admin-announcement-templates {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  padding-bottom: 50px;

  .button-margin-left {
    margin-left: 15px;
  }

  .quick-message-list-item {
    margin-bottom: 15px;
  }
}
</style>