<template>
  <div class="admin-edit-quick-incident-response">
    <loading-screen
      :is-loading="isSaving || isDeleting || isLoading"
    ></loading-screen>

    <div class="loaded" v-if="!isLoading && quickMessage">
      <div class="page-header">
        <h1 class="page-title">Edit Quick Incident Response</h1>
        <div class="actions">
          <a-button
            class="button-margin-left btn-rounded"
            icon="arrow-left"
            size="large"
            type="primary"
            @click.prevent="goBack"
            >Back</a-button
          >

          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="attemptDelete"
          >
            <a-button
              class="button-margin-left btn-rounded"
              icon="delete"
              size="large"
              type="danger"
              >Delete</a-button
            >
          </a-popconfirm>
        </div>
      </div>

      <div class="form">
        <a-form-item label="Title">
          <a-input
            size="large"
            v-model="quickMessage.displayName"
          ></a-input>
        </a-form-item>

        <a-form-item label="Description">
          <a-textarea
            :rows="6"
            size="large"
            v-model="quickMessage.body"
          ></a-textarea>
        </a-form-item>

        <a-form-item label="Incident Type">
          <a-select
            style="width: 100%"
            v-model="quickMessage.attributes.IncidentTypeId"
            placeholder="Select incident type (optional)"
            size="large"
            allowClear
          >
            <a-select-option
              v-for="type in incidentTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.displayName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Behavior">
          <a-radio-group v-model="quickMessage.attributes.AppendOnly">
            <a-radio :value="false">Replace existing content</a-radio>
            <a-radio :value="true">Append to existing content</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Visibility">
          <a-radio-group v-model="quickMessage.scope">
            <a-radio :value="1">Shown to organisation</a-radio>
            <a-radio :value="3">Hidden from lists</a-radio>
          </a-radio-group>
        </a-form-item>
      </div>

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import quickMessages from "../../../../api/quick-messages";
import incidentTypes from "../../../../api/incident-types";

export default {
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      quickMessage: null,
      incidentTypes: [],
    };
  },

  components: { LoadingScreen },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },

  methods: {
    goBack() {
      this.$router.push("/admin/settings/quick-incident-responses");
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      quickMessages
        .deleteQuickMessage(this.tenantId, this.quickMessage.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Quick incident response deleted successfully");
          vm.$router.push("/admin/settings/quick-incident-responses");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error deleting quick incident response");
          vm.isDeleting = false;
        });
    },

    loadQuickMessage() {
      let vm = this;
      vm.isLoading = true;
      quickMessages
        .getQuickMessage(vm.tenantId, vm.$route.params.id)
        .then((r) => {
          vm.isLoading = false;
          vm.quickMessage = r.data;
          // Initialize attributes if they don't exist
          if (!vm.quickMessage.attributes) {
            vm.quickMessage.attributes = {
              AppendOnly: false,
              IncidentTypeId: null
            };
          }
          // Convert AppendOnly from string to boolean
          if (vm.quickMessage.attributes.AppendOnly !== undefined) {
            vm.quickMessage.attributes.AppendOnly = vm.quickMessage.attributes.AppendOnly === "true";
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading quick incident response");
          vm.$router.push("/admin/settings/quick-incident-responses");
        });
    },

    loadIncidentTypes() {
      let vm = this;
      incidentTypes
        .getIncidentTypes(this.tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.incidentTypes = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error loading incident types");
        });
    },

    getValidationErrors() {
      let errors = [];
      if (this.quickMessage.displayName.trim().length == 0) {
        errors.push("Please provide a title");
      }
      if (this.quickMessage.body.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      // Create a copy of the quickMessage and convert AppendOnly to string
      const payload = {
        ...this.quickMessage,
        attributes: {
          ...this.quickMessage.attributes,
          AppendOnly: this.quickMessage.attributes.AppendOnly.toString()
        }
      };

      try {
        await quickMessages.updateQuickMessage(
          this.tenantId,
          payload
        );

        vm.isSaving = false;
        vm.$message.success("Quick incident response updated successfully");
        vm.$router.push("/admin/settings/quick-incident-responses");
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error("Error updating quick incident response");
      }
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadQuickMessage();
      this.loadIncidentTypes();
    }
  },
};
</script>

<style scoped lang="scss">
.admin-edit-quick-incident-response {
  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }

  padding-bottom: 30px;
}
</style>